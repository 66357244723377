<template>
  <div class="doc-wrapper">
    <div class="top-wrapper">
      <div class="top-search">
        <van-search
          v-model="searchInput"
          show-action
          placeholder="搜索科室或医生"
          @search="onClickButton"
          @clear="onClear"
        >
          <template #action>
            <div @click="onClickButton">搜索</div>
          </template>
        </van-search>
      </div>
      <div class="select-box">
        <van-field
          v-model="fieldValueArea"
          is-link
          readonly
          placeholder="选择区域"
          @click="showPicker.area = true"
        />
        <van-field
          v-model="fieldValueHospital"
          is-link
          readonly
          placeholder="选择机构"
          @click="showPicker.hospital = true"
        />
        <van-field
          v-model="fieldValueDepart"
          is-link
          readonly
          placeholder="选择科室"
          @click="showPicker.depart = true"
        />
      </div>
    </div>

    <div class="doctor-list" @click="handleDoctor">
      <div v-if="allList.length != 0">
        <div v-for="itemFat in allList" :key="itemFat.id" class="">
          <div class="hospital-box">
            <p class="hos-name">{{ itemFat.organizationName }}</p>
            <div class="hos-tag">
              <p>
                <span v-if="itemFat.organizationLevel == 0">四级甲等</span>
                <span v-if="itemFat.organizationLevel == 1">四级乙等</span>
                <span v-if="itemFat.organizationLevel == 2">三级</span>
                <span v-if="itemFat.organizationLevel == 3">三级甲等</span>
                <span v-if="itemFat.organizationLevel == 4">三级乙等</span>
                <span v-if="itemFat.organizationLevel == 5">三级丙等</span>
                <span v-if="itemFat.organizationLevel == 6">二级</span>
                <span v-if="itemFat.organizationLevel == 7">二级甲等</span>
                <span v-if="itemFat.organizationLevel == 8">二级乙等</span>
                <span v-if="itemFat.organizationLevel == 9">二级丙等</span>
                <span v-if="itemFat.organizationLevel == 10">一级</span>
                <span v-if="itemFat.organizationLevel == 11">一级甲等</span>
                <span v-if="itemFat.organizationLevel == 12">一级乙等</span>
                <span v-if="itemFat.organizationLevel == 13">一级丙等</span>
              </p>
              <p>
                <span v-if="itemFat.organizationType == 0">综合性医院</span>
                <span v-if="itemFat.organizationType == 1">专科医院</span>
              </p>
              <p>
                <span v-if="itemFat.organizationAttribute == 0">公立医院</span>
                <span v-if="itemFat.organizationAttribute == 1">私立医院</span>
                <span v-if="itemFat.organizationAttribute == 2">其他</span>
              </p>
            </div>
          </div>
          <div v-if="itemFat.doctorVos.length != 0">
            <div
              v-for="item in itemFat.doctorVos"
              :key="item.id"
              class="doctor-item"
              @click="toDetail(item)"
            >
              <van-image
                v-if="item.doctorHeadImg"
                round
                width="1rem"
                height="1rem"
                fit="contain"
                :src="item.doctorHeadImg"
              />
              <van-image
                v-else
                round
                width="1rem"
                height="1rem"
                fit="contain"
                src="http://maicc-test.oss-cn-beijing.aliyuncs.com/temp-folder/2023-02-01%5Cf5a4f8e4-4003-454f-84a1-4603d722ecd6-6a48ae42-04da-4b8a-9fe2-af62b1e4fc99.png"
              />
              <div class="doctor-rgt">
                <p class="rgt-one">
                  <span>
                    <span class="name">{{ item.doctorName }}</span>
                    <span class="text"
                      >{{
                        // item.departmentName
                        //   ? item.departmentName.split(",")[0]
                        //   : ""
                        item.jobDepartmentName
                      }}
                      |
                      {{
                        convertDoctorProfessional(item.doctorProfessional)
                      }}</span
                    >
                    <span v-if="item.isDoctorTeam == 1" class="team-box"
                      >团队</span
                    >
                    <span v-if="item.isOnTheJob == 1" class="team-box2"
                      >合作</span
                    >
                  </span>
                  <span>
                    <van-icon
                      v-if="item.isFollow == 1"
                      name="star"
                      color="#F79C42"
                      @click.stop="cancelFollowDoctor(item)"
                    />
                    <van-icon
                      v-else
                      name="star"
                      color="#E2E1E4"
                      @click.stop="followDoctor(item)"
                    />
                  </span>
                </p>
                <p class="doctor-text">{{ item.doctorGood }}</p>
                <p>
                  <span
                    class="doctor-tag"
                    v-for="item2 in item.dptTags"
                    :key="item2.id"
                    >{{ item2.roleName }}</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="empty-text">暂无专家推荐</div>
    </div>
    <div class="bot-wrapper">
      <div class="bot-msg">
        <span>
          <img
            src="../assets/img/doc1.png"
            alt=""
            style="width: 30px; margin-right: 10px"
          />
          不知道选择哪个科室？
        </span>
        <span class="zixun" @click="handleSave">
          在线咨询
          <img src="../assets/img/lft2.png" alt="" style="width: 20px" />
        </span>
      </div>
    </div>
  </div>
  <van-popup
    v-model:show="showPicker.area"
    round
    position="bottom"
    :lazy-render="false"
  >
    <van-picker
      ref="areaPicker"
      :columns="columnsArea"
      :columns-field-names="{ text: 'name', value: 'id' }"
      @cancel="showPicker.area = false"
      @confirm="onConfirmArea"
    />
  </van-popup>
  <van-popup v-model:show="showPicker.hospital" round position="bottom">
    <van-picker
      :columns="columnsHospital"
      :columns-field-names="{ text: 'organizationName', value: 'id' }"
      @cancel="showPicker.hospital = false"
      @confirm="onConfirmHospital"
    />
  </van-popup>
  <van-popup
    v-model:show="showPicker.depart"
    round
    position="bottom"
    class="depart-box"
  >
    <div class="all-depart" @click="lookMore">全部科室 ></div>
    <van-picker
      :columns="columnsDepart"
      :columns-field-names="{ text: 'departmentAliasName', value: 'id' }"
      @cancel="showPicker.depart = false"
      @confirm="onConfirmDepart"
    />
  </van-popup>
</template>
  
  <script>
import {
  Picker,
  Toast,
  Field,
  Popup,
  Cell,
  Calendar,
  Button,
  Icon,
  Tabs,
  Tab,
  Search,
  Dialog,
  Image,
  Notify,
} from "vant";

export default {
  name: "doctorPageArea",
  components: {
    [Picker.name]: Picker,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Cell.name]: Cell,
    [Calendar.name]: Calendar,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Search.name]: Search,
    [Dialog.name]: Dialog,
    [Image.name]: Image,
    [Notify.name]: Notify,
  },
  data() {
    return {
      searchInput: "",
      ListActive: "0",
      showPicker: {},
      columnsArea: [],
      columnsHospital: [],
      columnsDepart: [],
      fieldValueArea: "",
      fieldValueHospital: "全部",
      fieldValueHospitalId: "-1",
      fieldValueDepart: "全部",
      fieldValueDepartId: "-1",
      doctorList: [],
      allList: [],
      patList: [],
      isShow: {
        patList: false,
      },
    };
  },
  created() {},
  mounted() {
    this.fieldValueArea = this.$route.query.locationCity;
    if (this.$route.query.departId) {
      this.fieldValueDepartId = this.$route.query.departId;
      this.fieldValueDepart = this.$route.query.departName;
    }
    // if (this.$route.query.searchInput) {
    //   this.searchInput = this.$route.query.searchInput;
    // }
    if (this.$cache.session.getItem("searchInput")) {
      this.searchInput = this.$cache.session.getItem("searchInput");
    }
    if (this.$route.query.hospitalIdVal) {
      this.fieldValueHospitalId = this.$route.query.hospitalIdVal;
      this.fieldValueHospital = this.$route.query.hospitalNameVal;
      // this.queryDepartment(this.fieldValueHospitalId);
    }
    if (this.$cache.session.getItem("isLocationCity")) {
      this.fieldValueArea = this.$cache.session.getItem("locationCity");
      this.$nextTick(() => {
        this.$cache.session.removeItem("locationCity");
        this.$cache.session.removeItem("isLocationCity");
      });
    }

    this.queryAllArea();
    this.queryAllOrganization(this.fieldValueArea);

    console.log(this.fieldValueArea, " this.fieldValueArea11111");
    if (this.fieldValueHospitalId == "-1") {
      this.queryOrganizationId(1);
      this.queryBasicDepartment();
    } else {
      this.queryOrganizationId();
      this.queryDepartment(this.fieldValueHospitalId);
    }
  },
  methods: {
    // 搜索
    onClickButton() {
      this.$cache.session.setItem("searchInput", this.searchInput);
      // this.queryOrganizationId();
      if (this.fieldValueHospitalId == "-1") {
        this.queryOrganizationId(1);
      } else {
        this.queryOrganizationId();
      }
    },
    // 清除
    onClear() {
      this.$cache.session.removeItem("searchInput");
      // this.queryOrganizationId();
      if (this.fieldValueHospitalId == "-1") {
        this.queryOrganizationId(1);
      } else {
        this.queryOrganizationId();
      }
    },
    // 所有数据
    queryOrganizationId(type) {
      let postData = {
        patientId: this.$cache.local.getItem("patientId"),
		 addrName:(this.fieldValueHospitalId==-1||!this.fieldValueHospitalId)?this.fieldValueArea:"",
        organizationId: this.fieldValueHospitalId,
        searchInput: this.searchInput,
      };
      if (type == 1) {
        postData.standardDepartmentId = this.fieldValueDepartId;
      } else {
        postData.departmentId = this.fieldValueDepartId;
      }
      this.$http
        .request("queryOrganizationId", postData)
        .then((res) => {
          if (res) {
            this.allList = res;
          } else {
            this.allList = [];
          }
          console.log(res, "55555555");
        })
        .catch((err) => {});
    },
    // 区域
    queryAllArea() {
      this.$http
        .request("queryMapsQuery", {})
        .then((res) => {
          console.log(res, "地区");
          res.forEach((item, index) => {
            item.children = item.addrMaps;
            item.children.forEach((city, idx) => {
              if (this.fieldValueArea == city.name) {
                setTimeout(() => {
                  this.$refs.areaPicker.setIndexes([index, idx]);
                }, 0);
              }
            });
          });
          this.columnsArea = res;
        })
        .catch((err) => {});
    },
    // 机构
    queryAllOrganization(val) {
      this.$http
        .request("queryAreaOrganization", {
          addrName: val,
        })
        .then((res) => {
          // 后台返回字段有disabled（是否启用服务），关键字，会把picker选项禁用
          res.forEach((item) => {
            delete item.disabled;
          });
          res.unshift({
            id: -1,
            organizationName: "全部",
          });
          this.columnsHospital = res;
        })
        .catch((err) => {});
    },
    // 科室
    queryDepartment(val) {
      this.$http
        .request("queryDepartment", {
          addrName: this.fieldValueArea,
          organizationId: val,
        })
        .then((res) => {
          // 后台返回字段有disabled（是否启用服务），关键字，会把picker选项禁用
          res.forEach((item) => {
            delete item.disabled;
          });
          res.unshift({
            id: -1,
            departmentAliasName: "全部",
          });
          this.columnsDepart = res;
          console.log(res, "科室");
        })
        .catch((err) => {});
    },
    // 标准科室
    queryBasicDepartment() {
      this.$http
        .request("getDepartmentAllArea", {
          name: this.fieldValueArea,
        })
        .then((res) => {
          // 后台返回字段有disabled（是否启用服务），关键字，会把picker选项禁用
          res.forEach((item) => {
            delete item.disabled;
          });
          res.unshift({
            id: -1,
            departmentAliasName: "全部标准",
          });
          this.columnsDepart = res;
          console.log(res, "标准科室");
        })
        .catch((err) => {});
    },
    // 区域弹窗确认
    onConfirmArea(selectedOptions) {
      this.showPicker.area = false;
      this.fieldValueArea = selectedOptions[1].name;
      this.fieldValueHospital = "全部";
      this.fieldValueHospitalId = "-1";
      this.fieldValueDepart = "全部";
      this.fieldValueDepartId = "-1";
      this.queryOrganizationId();
      this.queryAllOrganization(selectedOptions[1].name);
      this.queryBasicDepartment();
    },
    // 机构弹窗确认
    onConfirmHospital(selectedOptions) {
      console.log(selectedOptions, "selectedOptions000");
      this.showPicker.hospital = false;
      this.fieldValueHospital = selectedOptions.organizationName;
      this.fieldValueHospitalId = selectedOptions.id;
      this.fieldValueDepart = "全部";
      this.fieldValueDepartId = "-1";
      this.queryOrganizationId();
      if (selectedOptions.id == "-1") {
        this.queryBasicDepartment();
      } else {
        this.queryDepartment(selectedOptions.id);
      }
    },
    // 科室弹窗确认
    onConfirmDepart(selectedOptions) {
      this.showPicker.depart = false;
      this.fieldValueDepart = selectedOptions.departmentAliasName;
      this.fieldValueDepartId = selectedOptions.id;
      if (this.fieldValueHospitalId == "-1") {
        this.queryOrganizationId(1);
      } else {
        this.queryOrganizationId();
      }
    },
    // 查看全部科室
    lookMore() {
      this.$cache.session.setItem("locationCity", this.fieldValueArea);
      this.$cache.session.setItem("isLocationCity", 1);
      this.$router.push(
        "/departmentAll?locationCity=" +
          this.fieldValueArea +
          "&isAreType=" +
          this.$route.query.isAreType
      );
    },
    // 关注
    followDoctor(item) {
      Toast.loading();
      this.$http
        .request("followDoctor", {
          id: this.$cache.local.getItem("patientId"),
          doctorId: item.id,
        })
        .then((res) => {
          // Notify({ type: "success", message: "关注成功" });
          Toast.success("关注成功");
          this.queryOrganizationId();
        })
        .catch((err) => {});
    },
    // 取消关注
    cancelFollowDoctor(item) {
      Dialog.confirm({
        title: "温馨提示",
        message: "您确认取消关注此医生吗？",
        confirmButtonColor: "#1989fa",
      })
        .then(() => {
          Toast.loading();
          this.$http
            .request("cancelFollowDoctor", {
              id: this.$cache.local.getItem("patientId"),
              doctorId: item.id,
            })
            .then((res) => {
              Toast.success("取关成功");
              this.queryOrganizationId();
            })
            .catch((err) => {});
        })
        .catch(() => {
          // on cancel
        });
    },
    // 详情
    toDetail(item) {
      // 获取当前市区id及名称
      console.log(this.columnsArea, this.fieldValueArea);
      for (let i in this.columnsArea) {
        let childrenList = this.columnsArea[i].children || [];
        for (let j in childrenList) {
          if (childrenList[j].name == this.fieldValueArea) {
            // this.$cache.session.setItem("addrId", childrenList[j].id);
            // this.$cache.session.setItem("addrName", this.fieldValueArea);
            this.$cache.session.setItem("locationCity", this.fieldValueArea);
            this.$cache.session.setItem("isLocationCity", 1);
			this.$http.request("cardCount",{id:item.id}).then(()=>{}).catch(()=>{})
            this.$router.push("/doctorDetail?docId=" + item.id);
            return;
          }
        }
      }
	  this.$http.request("cardCount",{id:item.id}).then(()=>{}).catch(()=>{})
      this.$router.push("/doctorDetail?docId=" + item.id);
    },
    convertDoctorProfessional(val) {
      if (val == 1) {
        return "主任医师";
      } else if (val == 2) {
        return "副主任医师";
      } else if (val == 3) {
        return "主治医师";
      } else if (val == 4) {
        return "医士";
      } else if (val == 5) {
        return "主任药师";
      } else if (val == 6) {
        return "副主任药师";
      } else if (val == 7) {
        return "主管药师";
      } else if (val == 8) {
        return "药士";
      } else if (val == 9) {
        return "主任护师";
      } else if (val == 10) {
        return "副主任护师";
      } else if (val == 11) {
        return "主管护师";
      } else if (val == 12) {
        return "护士";
      } else {
        return val;
      }
    },
    // 在线咨询
    handleSave() {
		this.$http.request("zxCount",{}).then(()=>{}).catch(()=>{})
      this.$router.push("/onConsult");
    },
  },
  beforeDestroy() {},
};
</script>
    
  
  <style lang="scss" scoped>
.doc-wrapper {
  position: relative;
  height: 100%;
  .top-wrapper {
    height: 100px;
    width: 100%;
    background-color: #fff;
    position: fixed;
    top: 0;
    border-bottom: 1px solid #eee;
    .top-search {
      height: 55px;
    }
    .select-box {
      display: flex;
    }
  }

  .bot-wrapper {
    width: 100%;
    background-color: #fff;
    height: 70px;
    line-height: 70px;
    position: fixed;
    bottom: 0;
    .bot-msg {
      width: calc(100% - 30px);
      background-color: #e8f3ff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 15px;
      padding: 0 15px;
      box-sizing: border-box;
      border-radius: 8px;
      height: 50px;
      line-height: 50px;
      font-size: 15px;
      position: fixed;
      bottom: 0;
      span {
        display: flex;
        align-items: center;
      }
      .zixun {
        color: #278eff;
      }
    }
  }
  .doctor-list {
    // background-color: rgb(127, 180, 255);
    height: calc(100% - 170px);
    overflow: auto;
    position: fixed;
    top: 100px;
    width: 100%;
    .hospital-box {
      background: -webkit-linear-gradient(top, #74c6ff, #1e88ff);
      height: 80px;
      padding: 0 15px;
      .hos-name {
        font-size: 18px;
        color: #fff;
        padding-top: 12px;
        padding-bottom: 8px;
      }
      .hos-tag {
        display: flex;
        > p {
          color: #fff;
          border: 1px solid #fff;
          margin-right: 5px;
          padding: 2px 5px;
          font-size: 12px;
        }
      }
    }
    .doctor-item {
      display: flex;
      // justify-content: center;
      align-items: center;
      border-bottom: 1px solid #ccc;
      padding: 15px;
      box-sizing: border-box;
      width: 100%;

      .doctor-rgt {
        margin-left: 15px;
        width: calc(100% - 80px);
        .rgt-one {
          display: flex;
          justify-content: space-between;
          .name {
            font-size: 16px;
            color: #272d3b;
            font-weight: bold;
          }
          .text {
            font-size: 13px;
            margin-left: 10px;
            color: #272d3b;
          }
          .cancel-follow {
            color: #9da7b1;
          }
          .team-box {
            color: #278eff;
            background-color: #e8f3fe;
            font-size: 12px;
            padding: 2px 5px;
            border-radius: 2px;
            margin-left: 5px;
          }
          .team-box2 {
            color: #2ac2a8;
            background-color: #d8fcf6;
            font-size: 12px;
            padding: 2px 5px;
            border-radius: 2px;
            margin-left: 5px;
          }
        }

        .doctor-text {
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          font-size: 13px;
          color: #b8c0c7;
          margin: 10px 0;
        }
        .doctor-tag {
          background-color: #fff5e6;
          color: #f79c42;
          font-size: 12px;
          border-radius: 2px;
          margin-right: 10px;
          padding: 0 5px;
        }
      }
    }
    .empty-text {
      color: #9da7b1;
      font-size: 16px;
      text-align: center;
      margin-top: 30px;
    }
  }
}

.depart-box {
  position: relative;
  .all-depart {
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
    color: #278eff;
    font-size: 14px;
  }
}
</style>